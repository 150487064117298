(function(window, document) {
  var WINDOW_CHANGE_EVENT = ('onorientationchange' in window) ? 'orientationchange' : 'resize';

  function fixAspect() {
    var imgs = document.querySelectorAll('[data-aspect]');
    [].forEach.call(imgs, function(div) {
      var width = div.offsetWidth;
      var ratio = div.getAttribute('data-aspect');
      div.style.height = width * ratio + 'px';
      div.style.objectFit = 'cover';
    });
  }

  window.addEventListener(WINDOW_CHANGE_EVENT, fixAspect);
  fixAspect();
})(this, this.document);
